import { useAuthStore } from '../stores/authStore';

export const useAuth = () => {
  const { user, isLoading, isAuthenticated, logout } = useAuthStore();
  
  const isSuperAdmin = user?.role === 'super_admin';
  const isAdmin = user?.role === 'admin' || isSuperAdmin;
  const isMerchant = user?.role === 'merchant' || isAdmin;

  return {
    user,
    isLoading,
    isAuthenticated,
    logout,
    isSuperAdmin,
    isAdmin,
    isMerchant,
  };
};